import * as React from "react";
import HeaderQuiz from "../components/HeaderQuiz";

const TermsPage = () => {
  return (
    <>
      <HeaderQuiz
				breadcrumbs={[
					{
						title: "Main",
						url: "/"
					},
					{
						title: "Terms",
						url: "/terms"
					}
				]}
			/>
      <div className="container">
				<div className="static-page">
					<h1 className="static-page__title">TERMS OF USE</h1>
					<p>
						<strong>
							PLEASE READ THIS AGREEMENT CAREFULLY AS IT GOVERNS YOUR USE OF THE SERVICES (AS DEFINED BELOW), UNLESS WE HAVE EXECUTED A SEPARATE WRITTEN AGREEMENT WITH YOU FOR THAT PURPOSE. BY CLICKING ON THE “I AGREE” OR SIMILAR BUTTON OR BY UTILIZING THE SERVICES, YOU ACCEPT ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY OR OTHER LEGAL ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND THE COMPANY OR OTHER LEGAL ENTITY TO THIS AGREEMENT. WE MAY MODIFY THIS AGREEMENT FROM TIME TO TIME, SUBJECT TO SECTION (CHANGES TO THIS AGREEMENT). YOUR CONTINUED USE OF THE SERVICES FOLLOWING THE POSTING OF CHANGES CONSTITUTES AGREEMENT TO AND ACCEPTANCE OF THESE CHANGES.
						</strong>
					</p>
					<p>
					The purpose of this assessment is to validate whether the in-scope application meets or exceeds the CASA guidelines as laid out above and should not be read as a comprehensive penetration test or maturity assessment.
					</p>
					<ul>
						<li>
							<a href="https://github.com/appdefensealliance/ASA/blob/main/CloudAppSecurity/CloudApplicationSecurityGuide.md#application-defense-alliance-cloud-security-guide-101" target="_blank">V2: Authentication Verification Requirements</a>
						</li>
						<li>
							<a href="https://github.com/appdefensealliance/ASA/blob/main/CloudAppSecurity/CloudApplicationSecurityGuide.md#application-defense-alliance-cloud-security-guide-101" target="_blank">V3: Session Management Verification Requirements</a>
						</li>
						<li>
							<a href="https://github.com/appdefensealliance/ASA/blob/main/CloudAppSecurity/CloudApplicationSecurityGuide.md#application-defense-alliance-cloud-security-guide-101" target="_blank">V4: Access Control Verification Requirements</a>
						</li>
						<li>
							<a href="https://github.com/appdefensealliance/ASA/blob/main/CloudAppSecurity/CloudApplicationSecurityGuide.md#application-defense-alliance-cloud-security-guide-101" target="_blank">V6: Stored Cryptography Verification Requirements</a>
						</li>
						<li>
							<a href="https://github.com/appdefensealliance/ASA/blob/main/CloudAppSecurity/CloudApplicationSecurityGuide.md#application-defense-alliance-cloud-security-guide-101" target="_blank">V8: Data Protection Verification Requirements</a>
						</li>
						<li>
							<a href="https://github.com/appdefensealliance/ASA/blob/main/CloudAppSecurity/CloudApplicationSecurityGuide.md#application-defense-alliance-cloud-security-guide-101" target="_blank">V9: Communications Verification Requirements</a>
						</li>
						<li>
							<a href="https://github.com/appdefensealliance/ASA/blob/main/CloudAppSecurity/CloudApplicationSecurityGuide.md#application-defense-alliance-cloud-security-guide-101" target="_blank">V13: API and Web Service Verification Requirements</a>
						</li>
						<li>
							<a href="https://github.com/appdefensealliance/ASA/blob/main/CloudAppSecurity/CloudApplicationSecurityGuide.md#application-defense-alliance-cloud-security-guide-101" target="_blank">V14: Configuration Verification Requirements</a>
						</li>
					</ul>
					<p>
						<strong>
							IN NO EVENT SHALL EITHER PARTY BE LIABLE, WHETHER IN CONTRACT, TORT OR OTHERWISE, FOR ANY INCIDENTAL, CONSEQUENTIAL, SPECIAL, EXEMPLARY OR INDIRECT DAMAGES, LOSSES, EXPENSES OR COSTS OF ANY KIND COSTS OF PROCUREMENT OF SUBSTITUTE OR REPLACEMENT GOODS OR SERVICES, LOST BUSINESS PROFITS OR REVENUE OR LOST OR CORRUPTED DATA ARISING OUT OF THIS AGREEMENT OR USE OF THE SERVICES OR DELIVERABLES, EVEN IF ADVISED OF THE POSSIBILITY.
						</strong>
					</p>
					<p>
						<strong>
							THE COMPANY’S TOTAL LIABILITY UNDER THIS AGREEMENT SHALL BE LIMITED TO THE FEES PAID BY CLIENT FOR THE SERVICES HEREUNDER.  NOTHING IN THIS AGREEMENT SHALL LIMIT THE CLIENT’S LIABILITY FOR ANY DAMAGE THAT CANNOT BE EXCLUDED BY LAW.
						</strong>
					</p>
					<p>
						<strong>
							TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY’S TOTAL CUMULATIVE LIABILITY TO CUSTOMER OR ANY THIRD PARTY UNDER THIS AGREEMENT, FROM ALL CAUSES OF ACTION AND ALL THEORIES OF LIABILITY, WILL BE LIMITED TO AND WILL NOT EXCEED THE FEES ACTUALLY PAID FOR THE CASA TEST.
						</strong>
					</p>
					<p>
						Governing Law. You agree that all matters relating to your access to or use of the Services, including all disputes, will be governed by the laws of the United States and by the laws of the State of Washington without regard to its conflicts of laws provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in King County, Washington, and waive any objection to such jurisdiction or venue. Any claim under these Terms of Use must be brought within one (1) year after the cause of action arises, or such claim or cause of action is barred. Claims made under the separate terms and conditions of purchase for goods and services are not subject to this limitation. No recovery may be sought or received for damages other than out-of-pocket expenses, except that the prevailing party will be entitled to costs and attorneys’ fees. In the event of any controversy or dispute between arising out of or in connection with your use of the Site, the parties shall attempt, promptly and in good faith, to resolve any such dispute. If we are unable to resolve any such dispute within a reasonable time (not to exceed thirty (30) days), then either party may submit such controversy or dispute to mediation. If the dispute cannot be resolved through mediation, then the parties shall be free to pursue any right or remedy available to them under applicable law.
					</p>
					<p>
						<strong>
							CLIENT AGREES THAT REMEDIES, OBLIGATIONS AND WARRANTIES HEREIN ARE EXCLUSIVE AND IN LIEU OF ANY OTHER REPRESENTATION, REMEDY OR WARRANTY INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
						</strong>
					</p>
				</div>
			</div>
    </>
  )
}

export default TermsPage;
